import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'

import ForgotPasswordContainer from 'containers/forgotPassword'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    padding: '1rem',
    width: '100vw',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.light
  },
  gridContainer: {
    height: 'inherit'
  }
}))

function ForgotPassword () {
  const classes = useStyles()
  return (
    <>
      <div className={classes.root}>
        <Grid
          container
          spacing={3}
          justify='center'
          alignItems='center'
          className={classes.gridContainer}
        >
          <ForgotPasswordContainer />
        </Grid>
      </div>
    </>
  )
}

export default ForgotPassword

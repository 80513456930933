import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useNavigate } from '@reach/router'
import { useSelector } from 'react-redux'

import MedButton from '@bit/medikura.med-lib-uno.med-button'

import { ReactComponent as UpgradeIcon } from 'assets/icons/upgrade_icon.svg'
import i18n from 'i18n'
import { isUserLoggedIn } from 'store/selectors/user'

const useStyles = makeStyles(theme => ({
  btn: {
    color: theme.palette.tertiary.contrastText,
    backgroundColor: theme.palette.tertiary.main,
    '&:hover': {
      backgroundColor: theme.palette.tertiary.dark
    }
  }
}))

function UpgradeButton () {
  const classes = useStyles()
  const navigate = useNavigate()
  const userLoggedIn = useSelector(isUserLoggedIn)

  const handleClick = () => {
    // TODO :: For basic user redirect to full-blown plan page (unAutneticated route TBD)
    if (userLoggedIn) {
      navigate('/settings/plan')
    }
  }

  return (
    <>
      <MedButton
        fullWidth
        size='large'
        className={classes.btn}
        startIcon={<UpgradeIcon className='MuiSvgIcon-root' />}
        onClick={handleClick}
        data-testid='upgrade-button'
      >
        {i18n.t('components.upgradeButton.btnText')}
      </MedButton>
    </>
  )
}

export default UpgradeButton

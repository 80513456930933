import React from 'react'
import ListItem from '@material-ui/core/ListItem'
import { makeStyles } from '@material-ui/styles'
import { useLocation, Link } from '@reach/router'

import i18n from 'i18n'

const useStyles = makeStyles(theme => ({
  active: {
    color: theme.palette.primary.main,
    textDecoration: 'none'
  },
  normal: {
    color: theme.palette.primary.contrastText,
    textDecoration: 'none'
  }
}))

const navItems = [
  {
    title: i18n.t('components.appNavItems.navLinks.reports'),
    route: '/reports'
  },
  {
    title: i18n.t('components.appNavItems.navLinks.followUps'),
    route: '/followups'
  },
  {
    title: i18n.t('components.appNavItems.navLinks.settings'),
    route: '/settings'
  }
]

function NavItem ({ index, isButton, testId }) {
  const location = useLocation()
  const classes = useStyles()

  const item = navItems[index]

  return (
    <>
      <ListItem key={index} button={isButton}>
        <Link
          to={item.route}
          className={`${
            location.pathname.includes(item.route)
              ? classes.active
              : classes.normal
          }`}
          data-testid={testId}
        >
          {item.title}
        </Link>
      </ListItem>
    </>
  )
}

export const ReportsNav = ({ isButton, testId }) => (
  <NavItem index={0} isButton={isButton} testId={testId} />
)
export const FollowUpsNav = ({ isButton, testId }) => (
  <NavItem index={1} isButton={isButton} testId={testId} />
)
export const SettingsNav = ({ isButton, testId }) => (
  <NavItem index={2} isButton={isButton} testId={testId} />
)

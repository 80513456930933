import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import { makeStyles } from '@material-ui/core/styles'

import DrawerMenu from './menu'

const useStyles = makeStyles(theme => ({
  menuButton: {
    marginRight: theme.spacing(1.5),
    color: theme.palette.secondary.contrastText
  }
}))

function AppDrawer () {
  const classes = useStyles()
  const [openDrawer, toggleDrawer] = React.useState(false)

  return (
    <>
      <IconButton
        edge='start'
        className={classes.menuButton}
        aria-label='open drawer'
        data-testid='drawer-icon'
        onClick={() => toggleDrawer(true)}
      >
        <MenuIcon />
      </IconButton>
      {openDrawer && <DrawerMenu handleOnClose={() => toggleDrawer(false)} />}
    </>
  )
}

export default AppDrawer

import React from 'react'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import { makeStyles } from '@material-ui/styles'

import AppDrawer from 'components/appDrawer'
import AppLogo from 'components/appLogo'
import { ReportsNav, FollowUpsNav } from 'components/appNavItems'
import SearchBar from 'components/searchBar'
import HeaderUserMenu from 'components/headerUserMenu'
import UpgradeButton from 'components/upgradeButton'
import SearchPopper from 'components/searchPopper'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    padding: '1rem 3.75rem',
    height: '4.625rem',
    alignItems: 'center',
    backgroundColor: theme.palette.secondary.main,
    position: 'relative',
    boxShadow: '0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset'
  },
  logo: {
    color: theme.palette.secondary.contrastText,
    width: '10rem',
    '& svg': {
      verticalAlign: 'middle'
    }
  },
  nav: {
    display: 'flex',
    '& > li': {
      width: 'auto'
    }
  }
}))

function AppHeader () {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Grid container alignItems='center'>
        <Grid container item xs={10} sm={5} md={2} lg={2} alignItems='center'>
          <Hidden mdUp>
            <AppDrawer />
          </Hidden>
          <div className={classes.logo}>
            <AppLogo />
          </div>
        </Grid>

        <Grid container item xs={1} sm={6} md={4} lg={4} alignItems='center'>
          <Hidden smDown>
            <Grid item xs={12} className={classes.nav}>
              <ReportsNav />
              <FollowUpsNav />
            </Grid>
          </Hidden>
        </Grid>

        <Hidden smDown>
          <Grid container item xs={2} sm={3} justify='flex-end'>
            <SearchBar />
          </Grid>
        </Hidden>

        <Hidden mdUp>
          <Grid container item xs={1} sm={1} justify='flex-end'>
            <SearchPopper />
          </Grid>
        </Hidden>

        <Hidden smDown>
          <Grid container item xs={2} justify='flex-end'>
            <Grid container item xs={7} md={10} lg={7} alignItems='center'>
              <UpgradeButton />
            </Grid>
          </Grid>
        </Hidden>

        <Hidden smDown>
          <Grid container item xs={1} justify='flex-end'>
            <HeaderUserMenu />
          </Grid>
        </Hidden>
      </Grid>
    </div>
  )
}

export default AppHeader

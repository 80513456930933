import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'

import MedTypography from '@bit/medikura.med-lib-uno.med-typography'

import { ReactComponent as LoginIllustration } from 'assets/illustrations/login.svg'
import LoginForm from 'containers/loginForm'
import i18n from 'i18n'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    height: '100vh',
    padding: theme.spacing(1.5),
    background: theme.palette.background.light
  },
  gridContainer: {
    height: '100%'
  },
  contentContainer: {
    height: '77vh',
    maxHeight: '600px',
    '@media (max-height:799px)': {
      maxHeight: '578px'
    },
    '@media (max-height:699px)': {
      maxHeight: '478px'
    },
    '@media (max-height:599px)': {
      height: '80vh'
    }
  }
}))

function Login () {
  const classes = useStyles()
  return (
    <>
      <div className={classes.root}>
        <Grid
          container
          spacing={3}
          justify='center'
          alignItems='center'
          className={classes.gridContainer}
        >
          <Grid
            container
            item
            xs={12}
            sm={10}
            xl={8}
            className={classes.contentContainer}
            justify='space-between'
          >
            <Hidden only='xs'>
              <Grid
                container
                item
                xs={12}
                sm={4}
                lg={4}
                xl={5}
                alignContent='space-between'
                className={classes.gridContainer}
              >
                <Grid item xs={12} sm={12}>
                  <MedTypography variant='h3' color='secondary'>
                    {i18n.t('pages.login.heading')}
                  </MedTypography>
                </Grid>
                <Grid item xs={11} sm={11}>
                  <LoginIllustration />
                </Grid>
              </Grid>
            </Hidden>

            <Grid
              item
              xs={12}
              sm={6}
              md={5}
              lg={5}
              xl={5}
              className={classes.gridContainer}
            >
              <Grid
                container
                justify='flex-end'
                className={classes.gridContainer}
              >
                <Grid item xs={12} lg={11} className={classes.gridContainer}>
                  <LoginForm />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default Login

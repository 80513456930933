import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import { useSelector } from 'react-redux'
import { isRecoverPasswordEmailSent } from 'store/selectors/user'

import { ReactComponent as ForgotPasswordIllustration } from 'assets/illustrations/forgot_password.svg'
import ConfirmEmailSent from 'containers/forgotPassword/confirmEmailSent'
import ForgotPasswordForm from 'containers/forgotPassword/form'

const useStyles = makeStyles(theme => ({
  gridContainer: {
    height: 'inherit'
  },
  forgotLink: {
    alignSelf: 'flex-start'
  }
}))

function ForgotPasswordContainer () {
  const classes = useStyles()
  const recoverPasswordEmailSent = useSelector(isRecoverPasswordEmailSent)
  return (
    <>
      {(recoverPasswordEmailSent && <ConfirmEmailSent />) || (
        <Grid
          container
          xs={12}
          sm={10}
          xl={8}
          className={classes.contentContainer}
          justify='space-between'
        >
          <Grid
            container
            item
            xs={12}
            sm={5}
            lg={4}
            xl={3}
            alignContent='space-between'
            className={classes.gridContainer}
          >
            <ForgotPasswordForm />
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={4}
            lg={4}
            xl={3}
            alignContent='space-between'
            className={classes.gridContainer}
          >
            <Grid item xs={12} sm={12}>
              <ForgotPasswordIllustration />
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default ForgotPasswordContainer

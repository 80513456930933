import { identityInstanceAxios as identity } from 'store/api'

const LOGIN_URL = '/pharma/login'

export async function loginAPI (data) {
  return identity.post(LOGIN_URL, {
    email: data.email,
    password: data.password
  })
}

import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'

import { logoutUser } from 'store/reducers/auth'
import i18n from 'i18n'

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.contrastText
  }
}))

export default function Logout () {
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleClick = async () => {
    await dispatch(logoutUser())
  }

  return (
    <>
      <MenuItem className={classes.root} onClick={handleClick}>
        {i18n.t('components.logout.btnText')}
      </MenuItem>
    </>
  )
}

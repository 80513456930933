import React from 'react'
import i18n from 'i18n'
import { makeStyles } from '@material-ui/styles'
import MedTypography from '@bit/medikura.med-lib-uno.med-typography'
import MedDivider from '@bit/medikura.med-lib-uno.med-divider'
import { Link, useLocation } from '@reach/router'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Grid from '@material-ui/core/Grid'

import Card from 'components/card'

const useStyles = makeStyles(theme => ({
  root: {},
  heading: {
    color: theme.palette.primary.contrastText
  },
  rootLeft: {
    paddingTop: theme.spacing(2)
  },
  rootRight: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  contentContainer: {
    backgroundColor: 'white',
    height: '100%',
    borderRadius: '12px'
  },
  navigation: {
    marginTop: theme.spacing(12)
  },
  spacer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  submenu: {
    marginLeft: theme.spacing(3)
  },
  settingsRoutesNormal: {
    textDecoration: 'none',
    color: theme.palette.secondary.main
  },
  settingsRoutesActive: {
    textDecoration: 'none',
    color: theme.palette.primary.main
  },
  leftNavContainer: {
    flexDirection: 'column',
    alignContent: 'center'
  }
}))

function SettingsContainer (props) {
  const classes = useStyles()
  const location = useLocation()

  return (
    <Grid container className={classes.root}>
      <Grid container item md={3} lg={3} className={classes.rootLeft}>
        <Grid container md={12} lg={12} className={classes.leftNavContainer}>
          <Grid item md={8} lg={8}>
            <Grid
              container
              md={12}
              lg={12}
              justify='center'
              className={classes.settingsHead}
            >
              <MedTypography variant='h2' className={classes.heading}>
                {i18n.t('containers.settings.heading')}
              </MedTypography>
            </Grid>
            <Grid container md={12} lg={12}>
              <List className={classes.navigation} disablePadding>
                <ListItem>
                  <Link
                    data-testid='profileNav'
                    className={`${
                      location.pathname.includes('/profile')
                        ? classes.settingsRoutesActive
                        : classes.settingsRoutesNormal
                    }`}
                    to='/settings/profile'
                  >
                    <MedTypography variant='h5' align='left'>
                      {i18n.t('containers.settings.navigation.profile')}
                    </MedTypography>
                  </Link>
                </ListItem>
                <div className={classes.spacer}>
                  <MedDivider />
                </div>
                <ListItem>
                  <Link
                    data-testid='accountNav'
                    className={`${
                      location.pathname.includes('/account')
                        ? classes.settingsRoutesActive
                        : classes.settingsRoutesNormal
                    }`}
                    to='/settings/account'
                  >
                    <MedTypography variant='h5' align='left'>
                      {i18n.t('containers.settings.navigation.account')}
                    </MedTypography>
                  </Link>
                </ListItem>
                <List className={classes.submenu} disablePadding>
                  <ListItem>
                    <Link
                      data-testid='changepasswordNav'
                      className={`${
                        location.pathname.includes('/changepassword')
                          ? classes.settingsRoutesActive
                          : classes.settingsRoutesNormal
                      }`}
                      to='/settings/changepassword'
                    >
                      <MedTypography variant='body2' align='left'>
                        {i18n.t('containers.settings.navigation.password')}
                      </MedTypography>
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link
                      data-testid='planNav'
                      className={`${
                        location.pathname.includes('/plan')
                          ? classes.settingsRoutesActive
                          : classes.settingsRoutesNormal
                      }`}
                      to='/settings/plan'
                    >
                      <MedTypography variant='body2' align='left'>
                        {i18n.t('containers.settings.navigation.plan')}
                      </MedTypography>
                    </Link>
                  </ListItem>
                </List>
                <div className={classes.spacer}>
                  <MedDivider />
                </div>
                <ListItem>
                  <Link
                    data-testid='teamNav'
                    className={`${
                      location.pathname.includes('/team')
                        ? classes.settingsRoutesActive
                        : classes.settingsRoutesNormal
                    }`}
                    to='/settings/team'
                  >
                    <MedTypography variant='h5' align='left'>
                      {i18n.t('containers.settings.navigation.team')}
                    </MedTypography>
                  </Link>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={8} lg={8} className={classes.rootRight}>
        <Card className={classes.contentContainer}>{props.children}</Card>
      </Grid>
    </Grid>
  )
}

export default SettingsContainer

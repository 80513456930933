import React from 'react'
import ProfileHeader from './header'
import ProfileForm from './form'

export default function Profile (params) {
  return (
    <>
      <ProfileHeader />
      <ProfileForm />
    </>
  )
}

import React from 'react'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import { Formik, Form } from 'formik'
import { has } from 'lodash'
import { useLocation } from '@reach/router'

import MedButton from '@bit/medikura.med-lib-uno.med-button'
import MedTextField from '@bit/medikura.med-lib-uno.med-text-field'
import MedTypography from '@bit/medikura.med-lib-uno.med-typography'

import Card from 'components/card'
import i18n from 'i18n'
import { sendForgotPassEmail } from 'store/reducers/passwordRecovery'
import AppLogo from 'components/appLogo'
import { isValidEmail } from 'utils/validation-utility'

const useStyles = makeStyles(theme => ({
  root: {
    height: 'inherit',
    width: '100%'
  },
  formContainer: {
    height: 'inherit',
    textAlign: 'center',
    padding: theme.spacing(9, 4),
    '@media (max-height:899px)': {
      padding: theme.spacing(7, 4)
    },
    '@media (max-height:799px)': {
      padding: theme.spacing(4.5, 4)
    },
    '@media (max-height:699px)': {
      padding: theme.spacing(3.5, 4)
    }
  },
  title: {
    paddingBottom: theme.spacing(8),
    '@media (max-height:899px)': {
      paddingBottom: theme.spacing(6)
    },
    '@media (max-height:799px)': {
      paddingBottom: theme.spacing(3.5)
    },
    '@media (max-height:699px)': {
      paddingBottom: theme.spacing(2.5)
    }
  },
  btnWrapper: {
    margin: theme.spacing(7, 'auto', 0, 'auto'),
    '@media (max-height:899px)': {
      marginTop: theme.spacing(2.5)
    },
    '@media (max-height:799px)': {
      marginTop: theme.spacing(2)
    },
    '@media (max-height:699px)': {
      marginTop: theme.spacing(1.5)
    },

    '& button': {
      margin: 0
    }
  },
  logoWrapper: {
    color: theme.palette.secondary.main,
    paddingBottom: theme.spacing(7),
    '@media (max-height:799px)': {
      paddingBottom: theme.spacing(3.5)
    },
    '@media (max-height:699px)': {
      paddingBottom: theme.spacing(2.5)
    }
  },
  logo: {
    width: '50%',
    margin: '0 auto'
  }
}))

function ForgotPasswordForm () {
  const location = useLocation()
  const classes = useStyles()
  const dispatch = useDispatch()
  return (
    <>
      <Card className={classes.root}>
        <div className={classes.formContainer}>
          <Formik
            initialValues={{ email: '' }}
            validate={values => {
              const errors = {}
              if (!values.email) {
                errors.email = i18n.t(
                  'containers.forgotPassword.errors.missing'
                )
              } else if (!isValidEmail(values.email)) {
                errors.email = i18n.t(
                  'containers.forgotPassword.errors.invalid'
                )
              }
              return errors
            }}
            onSubmit={async (values, { setSubmitting }) => {
              await dispatch(
                sendForgotPassEmail({
                  email: values.email,
                  callbackURL: `${location.protocol}//${location.host}/login/newPassword`
                })
              )

              setSubmitting(false)
            }}
          >
            {({
              dirty,
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              isSubmitting
            }) => (
              <Form>
                <div className={classes.logoWrapper}>
                  <div className={classes.logo}>
                    <AppLogo />
                  </div>
                </div>
                <MedTypography
                  data-testid='forgotPasswordTitle'
                  variant='h3'
                  color='secondary'
                  align='center'
                  className={classes.title}
                >
                  {i18n.t('containers.forgotPassword.heading')}
                </MedTypography>
                <MedTypography variant='body1' color='secondary' align='left'>
                  {i18n.t('containers.forgotPassword.emailLabel')}
                </MedTypography>
                <MedTextField
                  variant='outlined'
                  placeholder='E-Mail'
                  type='email'
                  name='email'
                  data-testid='email'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  value={values.email}
                  error={has(errors, 'email')}
                  helperText={has(errors, 'email') ? errors.email : ' '}
                />
                <Grid
                  item
                  xs={12}
                  sm={10}
                  md={9}
                  className={classes.btnWrapper}
                >
                  <MedButton
                    type='submit'
                    data-testid='sendEmailBtn'
                    fullWidth
                    disabled={!dirty || isSubmitting || has(errors, 'email')}
                  >
                    {i18n.t('containers.forgotPassword.button')}
                  </MedButton>
                </Grid>
              </Form>
            )}
          </Formik>
        </div>
      </Card>
    </>
  )
}

export default ForgotPasswordForm

import React from 'react'
import { Router } from '@reach/router'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import MedThemeWrapper from '@bit/medikura.med-lib-uno.med-theme-wrapper'

import { store, persistor } from 'store'

import Login from 'pages/login'
import ForgotPassword from 'pages/forgotPassword'
import ReportsList from 'pages/reportsList'
import NotFound from 'pages/notFound'
import NewPassword from 'pages/newPassword'
import SettingsProfile from 'pages/settings/profile'
import SettingsTeam from 'pages/settings/team'
import SettingsPassword from 'pages/settings/password'
import SettingsPlan from 'pages/settings/plan'

import ProtectedRoute from 'containers/routeContainers/protectedRoute'
import Root from 'containers/routeContainers/authRoot'
import Home from 'containers/routeContainers/home'
import Settings from 'containers/routeContainers/settings'
import SettingsHome from 'containers/routeContainers/settingsHome'
import FollowUps from 'containers/followUps'

function App () {
  return (
    <>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <MedThemeWrapper theme='engage'>
            <Router>
              <ProtectedRoute component={Root} path='/'>
                <Home path='/' />
                <ReportsList path='reports' />
                <Settings path='settings'>
                  <SettingsHome path='/' />
                  <SettingsProfile path='profile' />
                  <SettingsTeam path='team' />
                  <SettingsPassword path='changepassword' />
                  <SettingsPlan path='plan' />
                </Settings>
                <FollowUps path='followups' />
                <NotFound default />
              </ProtectedRoute>
              <Login path='login' />
              <ForgotPassword path='forgotPassword' />
              <NewPassword path='login/newPassword' />
              <ReportsList path='reports' />
              <NotFound default />
            </Router>
          </MedThemeWrapper>
        </PersistGate>
      </Provider>
    </>
  )
}

export default App

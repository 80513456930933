import React from 'react'
import Drawer from '@material-ui/core/Drawer'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import { makeStyles } from '@material-ui/core/styles'

import AppLogo from 'components/appLogo'
import { ReportsNav, FollowUpsNav, SettingsNav } from 'components/appNavItems'
import Logout from 'components/logout'

const useStyles = makeStyles(theme => ({
  root: {
    width: '35vw',
    backgroundColor: theme.palette.secondary.main
  },
  logoWrapper: {
    display: 'flex',
    padding: '1rem 3.75rem',
    height: '4.625rem',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.secondary.contrastText}`
  },
  logo: {
    color: theme.palette.secondary.contrastText,
    width: '10rem',
    '& svg': {
      verticalAlign: 'middle'
    }
  },
  listNav: {
    padding: '1rem 3.75rem',
    '& > *': {
      marginBottom: theme.spacing(1)
    },
    '& .MuiDivider-root': {
      backgroundColor: theme.palette.secondary.contrastText
    }
  }
}))

function DrawerMenu ({ handleOnClose }) {
  const classes = useStyles()

  return (
    <>
      <Drawer
        anchor='left'
        open
        onClose={handleOnClose}
        classes={{ paper: classes.root }}
        data-testid='drawer-menu'
      >
        <div className={classes.logoWrapper}>
          <div className={classes.logo}>
            <AppLogo />
          </div>
        </div>

        <List component='nav' className={classes.listNav} aria-label='menu'>
          <ReportsNav isButton testId='drawer-reports-nav' />
          <FollowUpsNav isButton testId='drawer-follow-ups-nav' />
          <Divider />
          <SettingsNav isButton testId='drawer-settings-nav' />
          <Divider />
          <Logout />
        </List>
      </Drawer>
    </>
  )
}

export default DrawerMenu

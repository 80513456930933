import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import createExpirationTransform from 'redux-persist-transform-expire'
import { createBlacklistFilter } from 'redux-persist-transform-filter'

import rootReducer from './reducers'

const expireTransform = createExpirationTransform({
  expireKey: 'persistExpiresAt'
})

const blacklistAuthProperties = createBlacklistFilter('auth', ['loading'])
const blacklistUserProperties = createBlacklistFilter('user', [
  'dataUpdateAttempt',
  'dataUpdateSuccess'
])

const rootPersistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
  whitelist: ['auth', 'user'],
  blacklist: ['password'],
  transforms: [
    expireTransform,
    blacklistAuthProperties,
    blacklistUserProperties
  ]
}

const persistedReducer = persistReducer(rootPersistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
    }
  }),
  devTools: process.env.NODE_ENV !== 'production'
})

export const persistor = persistStore(store)

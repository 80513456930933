import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import Alert from '@material-ui/lab/Alert'
import { Formik, Form } from 'formik'

import MedButton from '@bit/medikura.med-lib-uno.med-button'
import MedTextField from '@bit/medikura.med-lib-uno.med-text-field'
import MedTypography from '@bit/medikura.med-lib-uno.med-typography'
import MedDropdown from '@bit/medikura.med-lib-uno.med-drop-down'

import i18n from 'i18n'
import { updateUserData } from 'store/reducers/user'
import {
  retrieveUserData,
  getUserToken,
  userUpdateAttempt,
  userUpdateSuccess
} from 'store/selectors/user'

const useStyles = makeStyles(theme => ({
  root: {},
  textField: {
    paddingBottom: theme.spacing(1.25),
    '&input:-webkit-autofill': {
      animation: 'onAutoFillStart'
    },
    '&input:not(:-webkit-autofill)': {
      animation: 'onAutoFillCancel'
    }
  },
  gridContainer: {
    justifyContent: 'center'
  },
  formContainer: {
    width: '100%'
  },
  alertBoxWrapper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    minHeight: theme.spacing(5)
  },
  labels: {
    paddingBottom: theme.spacing(1)
  }
}))

function ProfileForm () {
  const classes = useStyles()
  const dispatch = useDispatch()
  const userData = useSelector(retrieveUserData)
  const userToken = useSelector(getUserToken)
  const userUpdateAttempted = useSelector(userUpdateAttempt)
  const userUpdateSucceeded = useSelector(userUpdateSuccess)

  return (
    <Grid container className={classes.gridContainer}>
      <Grid container className={classes.gridContainer} lg={8} md={10} xs={12}>
        <Grid item lg={8} md={7} xs={12}>
          <div className={classes.formContainer}>
            <Formik
              initialValues={{
                firstName: userData.name && userData.name.firstName,
                lastName: userData.name && userData.name.lastName,
                language: userData.language || 'de',
                phoneNumber: userData.phoneNumber
              }}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                const { firstName, lastName, language, ...rest } = values
                const dataToSave = {
                  name: {
                    firstName,
                    lastName
                  },
                  position: userData.position,
                  ...rest
                }

                if (language) {
                  // change language only if not empty
                  dataToSave.language = language
                } else {
                  // needed to reset the form with the language previously selected
                  values.language = userData.language
                }

                await dispatch(updateUserData(dataToSave, userToken))
                if (userUpdateAttempted && userUpdateSucceeded) {
                  setSubmitting(false)
                }
                resetForm({ values })
              }}
            >
              {({
                dirty,
                values,
                handleChange,
                handleBlur,
                isSubmitting,
                isValid
              }) => (
                <Form>
                  <MedTypography
                    variant='body1'
                    color='secondary'
                    align='left'
                    className={classes.labels}
                  >
                    {i18n.t('containers.profileForm.firstName')}
                  </MedTypography>
                  <MedTextField
                    variant='outlined'
                    className={classes.textField}
                    fullWidth
                    placeholder='John'
                    type='text'
                    name='firstName'
                    data-testid='firstName'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    autoFocus
                    value={values.firstName}
                  />
                  <br />
                  <br />
                  <MedTypography
                    variant='body1'
                    color='secondary'
                    align='left'
                    className={classes.labels}
                  >
                    {i18n.t('containers.profileForm.lastName')}
                  </MedTypography>
                  <MedTextField
                    variant='outlined'
                    className={classes.textField}
                    fullWidth
                    placeholder='Müller'
                    type='text'
                    name='lastName'
                    data-testid='lastName'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    value={values.lastName}
                  />
                  <br />
                  <br />
                  <MedTypography
                    variant='body1'
                    color='secondary'
                    align='left'
                    className={classes.labels}
                  >
                    {i18n.t('containers.profileForm.phoneNumber')}
                  </MedTypography>
                  <MedTextField
                    variant='outlined'
                    className={classes.textField}
                    fullWidth
                    placeholder='004915238503581'
                    type='text'
                    name='phoneNumber'
                    data-testid='phoneNumber'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.phoneNumber}
                  />
                  <br />
                  <br />
                  <MedTypography
                    variant='body1'
                    color='secondary'
                    align='left'
                    className={classes.labels}
                  >
                    {i18n.t('containers.profileForm.language')}
                  </MedTypography>
                  <MedDropdown
                    variant='outlined'
                    className={classes.textField}
                    textKey='text'
                    valueKey='value'
                    dataList={[
                      { value: 'en', text: 'English' },
                      { value: 'de', text: 'Deutsch' }
                    ]}
                    fullWidth
                    placeholder={i18n.t('containers.profileForm.pleaseChoose')}
                    type='text'
                    name='language'
                    data-testid='language'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.language}
                  />
                  <div className={classes.alertBoxWrapper}>
                    {userUpdateAttempted && !userUpdateSucceeded && (
                      <Alert severity='error'>
                        {i18n.t('containers.profileForm.messages.error')}
                      </Alert>
                    )}
                    {userUpdateAttempted && userUpdateSucceeded && (
                      <Alert severity='success'>
                        {i18n.t('containers.profileForm.messages.success')}
                      </Alert>
                    )}
                  </div>
                  <Grid container justify='flex-end'>
                    <Grid
                      item
                      sm={5}
                      md={5}
                      lg={4}
                      className={classes.btnWrapper}
                    >
                      {!isSubmitting && (
                        <MedButton
                          type='submit'
                          data-testid='saveBtn'
                          fullWidth
                          disabled={isSubmitting || !isValid || !dirty}
                        >
                          {i18n.t('containers.profileForm.button')}
                        </MedButton>
                      )}
                      {isSubmitting && (
                        <CircularProgress
                          size={24}
                          className={classes.progressIconOnBtn}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </div>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ProfileForm

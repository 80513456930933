import React, { useEffect, useState, useMemo } from 'react'
import { Avatar } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { get } from 'lodash'
import helpers from 'utils/helpers'

const useStyles = makeStyles(theme => ({
  root: options => ({
    backgroundColor: theme.palette.profileColors[options.seededNumber],
    fontSize: options.fontSize
      ? theme.typography.pxToRem(options.fontSize)
      : 'auto'
  }),
  small: {
    width: theme.spacing(4), // 32x32
    height: theme.spacing(4)
  },
  medium: {
    width: theme.spacing(5), // 40x40
    height: theme.spacing(5)
  },
  large: {
    width: theme.spacing(10), // 80x80
    height: theme.spacing(10)
  }
}))

function UserAvatar ({ userData, properties = {} }) {
  const [lettersToDisplay, setLettersToDisplay] = useState('')
  const [seededNumber, setSeededNumber] = useState()
  const classes = useStyles({ seededNumber, ...properties })
  const memoizedRandomNumber = useMemo(
    () => helpers.getSeededRandomNumber(userData._id),
    [userData._id]
  )

  useEffect(() => {
    const firstName = get(userData, 'name.firstName', '')
    const lastName = get(userData, 'name.lastName', '')
    setSeededNumber(memoizedRandomNumber)
    setLettersToDisplay(
      (firstName.charAt(0) + lastName.charAt(0)).toUpperCase()
    )
  }, [userData, memoizedRandomNumber])

  return (
    <Avatar
      className={`${classes.root} ${
        properties.size ? classes[properties.size] : classes.medium
      }`}
      src={userData.profileImageUrl}
      data-testid='avatar'
    >
      {lettersToDisplay}
    </Avatar>
  )
}

export default UserAvatar

import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useSelector, useDispatch } from 'react-redux'
import { Grid } from '@material-ui/core'
import { get } from 'lodash'
import MedTypography from '@bit/medikura.med-lib-uno.med-typography'
import Chip from '@material-ui/core/Chip'
import { retrieveUserData, getUserToken } from 'store/selectors/user'
import { getUserData } from 'store/reducers/user'
import UserAvatar from 'components/userAvatar'

const useStyles = makeStyles(theme => ({
  gridContainer: {
    justifyContent: 'center'
  },
  nameAndAvatarContainer: {
    marginBottom: theme.spacing(4)
  },
  textContainer: {
    alignSelf: 'center'
  },
  text: {
    color: theme.palette.secondary.main,
    lineHeight: 1.7
  },
  subtext: {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 'normal'
  },
  chip: {
    height: theme.spacing(3),
    marginTop: theme.spacing(1)
  },
  hiddenChip: {
    visibility: 'hidden'
  }
}))

export default function ProfileHeader () {
  const classes = useStyles()
  const dispatch = useDispatch()
  const userData = useSelector(retrieveUserData)
  const token = useSelector(getUserToken)

  useEffect(() => {
    if (!userData.email) {
      dispatch(getUserData(token))
    }
  }, [])

  return (
    <Grid container className={classes.gridContainer}>
      <Grid container className={classes.gridContainer} md={12} lg={8} xl={8}>
        <Grid
          container
          item
          xs={12}
          md={12}
          lg={12}
          xl={6}
          className={classes.nameAndAvatarContainer}
        >
          <Grid
            item
            xs={12}
            md={3}
            lg={2}
            xl={3}
            className={classes.avatarContainer}
          >
            <UserAvatar
              userData={userData}
              properties={{
                size: 'large',
                fontSize: '40'
              }}
            />
          </Grid>
          <Grid item xs={12} md={9} lg={10} xl={9}>
            <MedTypography className={classes.text} variant='body1'>
              {get(userData, 'name.firstName')} {get(userData, 'name.lastName')}
            </MedTypography>
            <MedTypography
              className={`${classes.text} ${classes.subtext}`}
              variant='body1'
              data-testid='headerEmail'
            >
              {userData.email}
            </MedTypography>
            <Chip
              className={`${classes.subtext} ${classes.chip}`}
              label={userData.position && userData.position.toUpperCase()}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} lg={8} xl={6}>
          <MedTypography className={classes.text}>Account ID</MedTypography>
          <MedTypography className={`${classes.text} ${classes.subtext}`}>
            {userData._id}
          </MedTypography>
          <Chip className={`${classes.subtext} ${classes.hiddenChip}`} />
        </Grid>
      </Grid>
    </Grid>
  )
}

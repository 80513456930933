import React, { useState, useEffect } from 'react'
import { makeStyles, withStyles } from '@material-ui/styles'

import Tooltip from '@material-ui/core/Tooltip'
import MedTypography from '@bit/medikura.med-lib-uno.med-typography'
import passStrengthCalculator from 'passwordmeter'
import { ReactComponent as SadFaceIcon } from 'assets/icons/sadFace.svg'
import { ReactComponent as NeutralFaceIcon } from 'assets/icons/neutralFace.svg'
import { ReactComponent as HappyFaceIcon } from 'assets/icons/happyFace.svg'
import i18n from 'i18n'

import config from 'config'

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    '@media (max-width:960px)': {
      display: 'none'
    },
    width: '334px',
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
    maxWidth: 334,
    height: '140px',
    fontSize: theme.typography.pxToRem(12),
    left: '40px',
    borderRadius: '8px',
    boxShadow:
      '-2px 0px 4px rgba(0, 0, 0, 0.12), 0px 4px 4px rgba(0, 0, 0, 0.12)',
    transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)'
  },
  arrow: {
    color: theme.palette.background.paper,
    fontSize: '30px',
    borderRadius: '8px',
    left: '2px !important',
    '&::before': {
      boxShadow:
        '-2px 0px 4px rgba(0, 0, 0, 0.12), 0px 4px 4px rgba(0, 0, 0, 0.12)',
      borderRadius: '4px'
    }
  }
}))(Tooltip)

const useStyles = makeStyles(theme => ({
  strengthColorContainer: {
    height: '8px',
    display: 'flex',
    placeContent: 'space-between'
  },
  strengthColor: {
    width: '90px',
    backgroundColor: theme.palette.action.disabledBackground,
    borderRadius: '8px'
  },
  strengthIconContainer: {
    height: '35px'
  },
  strengthIcon: {
    float: 'right'
  },
  strengthDescriptionContainer: {
    marginTop: '10px'
  },
  scorelow: {
    backgroundColor: theme.palette.error.main
  },
  scoremedium: {
    backgroundColor: theme.palette.warning.main
  },
  scorehigh: {
    backgroundColor: theme.palette.success.main
  }
}))

export default function PasswordMeter (props) {
  const classes = useStyles()
  const { open, password } = props
  const [strength, setStrength] = useState()

  useEffect(() => {
    // 0 - 100 or -1 for empty password
    const passScore = passStrengthCalculator.checkPass(password)
    const strength =
      passScore > 90
        ? config.PASSWORD_STRENGTH_HIGH
        : passScore < 66
        ? config.PASSWORD_STRENGTH_LOW
        : config.PASSWORD_STRENGTH_MEDIUM
    setStrength(strength)

    return () => {
      setStrength('')
    }
  }, [password])

  return (
    <>
      <HtmlTooltip
        arrow
        open={open}
        placement='right'
        title={
          <>
            <MedTypography
              variant='h4'
              color='secondary'
              style={{ float: 'left' }}
            >
              {i18n.t('containers.newPassword.strength.heading')}
            </MedTypography>
            <br />
            <div className={classes.strengthIconContainer}>
              {strength === config.PASSWORD_STRENGTH_LOW ? (
                <SadFaceIcon className={classes.strengthIcon} />
              ) : strength === config.PASSWORD_STRENGTH_MEDIUM ? (
                <NeutralFaceIcon className={classes.strengthIcon} />
              ) : (
                <HappyFaceIcon className={classes.strengthIcon} />
              )}
            </div>
            <div className={classes.strengthColorContainer}>
              <div
                className={`${classes.strengthColor} ${
                  classes['score' + strength]
                }`}
              />
              <div
                className={`${classes.strengthColor} ${
                  strength === config.PASSWORD_STRENGTH_MEDIUM ||
                  strength === config.PASSWORD_STRENGTH_HIGH
                    ? classes['score' + strength]
                    : ''
                }`}
              />
              <div
                className={`${classes.strengthColor} ${
                  strength === config.PASSWORD_STRENGTH_HIGH
                    ? classes['score' + strength.toString()]
                    : ''
                }`}
              />
            </div>
            <div className={classes.strengthDescriptionContainer}>
              <MedTypography variant='body2' color='secondary'>
                {i18n.t(`containers.newPassword.strength.${strength}`)}
              </MedTypography>
            </div>
          </>
        }
      >
        {props.children}
      </HtmlTooltip>
    </>
  )
}

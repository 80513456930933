import { identityInstanceAxios as identity } from 'store/api'

const USER_DATA_API_URL = '/pharma/details'
const USER_DATA_UPDATE = '/pharma/'

export async function getUserDataApi (authToken) {
  return identity.get(USER_DATA_API_URL, {
    headers: { Authorization: authToken }
  })
}

export async function updateUserDataApi (data, authToken) {
  return identity.patch(USER_DATA_UPDATE, data, {
    headers: { Authorization: authToken }
  })
}

import { createSlice } from '@reduxjs/toolkit'

import {
  sendForgotPassEmailAPI,
  setNewPasswordAPI
} from 'store/api/passwordRevovery'

export const initialState = {
  loading: false,
  error: false,
  recoverPasswordEmailSent: false,
  changed: false,
  tokenAlreadyUsed: false
}

const authSlice = createSlice({
  name: 'password',
  initialState,
  reducers: {
    forgotPasswordRequest: state => {
      state.loading = true
    },
    forgotPasswordSuccess: (state, action) => {
      state.loading = false
      state.error = false
      state.recoverPasswordEmailSent = true
    },
    forgotPasswordFail: (state, action) => {
      state.loading = false
      state.error = action.payload.error
      state.recoverPasswordEmailSent = false
    },
    setNewPasswordRequest: state => {
      state.loading = true
    },
    setNewPasswordSuccess: (state, action) => {
      state.changed = true
      state.loading = false
      state.error = false
    },
    setNewPasswordFail: (state, action) => {
      state.loading = false
      state.error = action.payload
      state.tokenAlreadyUsed = false
    },
    setNewPasswordTokenFailed: (state, action) => {
      state.loading = false
      state.error = action.payload
      state.tokenAlreadyUsed = true
    }
  }
})

export const { name, actions, reducer } = authSlice

export const {
  forgotPasswordRequest,
  forgotPasswordSuccess,
  forgotPasswordFail,
  setNewPasswordRequest,
  setNewPasswordSuccess,
  setNewPasswordFail,
  setNewPasswordTokenFailed
} = actions

// action creators

export const sendForgotPassEmail = data => async dispatch => {
  try {
    dispatch(forgotPasswordRequest())
    const res = await sendForgotPassEmailAPI(data)

    if (res.status === 200) {
      dispatch(forgotPasswordSuccess())
    }
  } catch (err) {
    if (err.response.data) {
      dispatch(forgotPasswordFail(err.response.data))
    }
  }
}

export const setNewPassword = (data, token) => async dispatch => {
  try {
    dispatch(setNewPasswordRequest())
    const res = await setNewPasswordAPI(data, token)

    if (res.status === 201) {
      dispatch(setNewPasswordSuccess())
    }
  } catch (err) {
    if (err.response.status === 401) {
      dispatch(setNewPasswordTokenFailed(err.response.data))
    } else if (err.response.data) {
      dispatch(setNewPasswordFail(err.response.data))
    }
  }
}

import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import { useSelector } from 'react-redux'

import NewPasswordForm from 'containers/newPassword'
import ConfirmPasswordChanged from 'containers/newPassword/confirmPasswordChanged'
import { isPasswordChanged } from 'store/selectors/user'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    padding: '1rem',
    width: '100vw',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.light
  },
  gridContainer: {
    height: 'inherit'
  }
}))

function NewPassword () {
  const passwordChanged = useSelector(isPasswordChanged)
  const classes = useStyles()
  return (
    <>
      <div className={classes.root}>
        <Grid
          container
          spacing={3}
          justify='center'
          alignItems='center'
          className={classes.gridContainer}
        >
          {(passwordChanged && <ConfirmPasswordChanged />) || (
            <Grid
              item
              xs={12}
              sm={7}
              md={5}
              lg={4}
              xl={3}
              className={classes.contentContainer}
              justify='center'
            >
              <NewPasswordForm />
            </Grid>
          )}
        </Grid>
      </div>
    </>
  )
}

export default NewPassword

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Background from 'assets/img/background.svg'
import AppHeader from 'components/appHeader'

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 'calc(100vh - 4.625rem)', // height of header from 'appHeader'
    backgroundImage: `url(${Background})`
  }
}))

function Root (props) {
  const classes = useStyles()

  return (
    <>
      <AppHeader />
      <div className={classes.root}>{props.children}</div>
    </>
  )
}

export default Root

import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, Link } from '@reach/router'
import { makeStyles } from '@material-ui/styles'
import { Grid, CircularProgress } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { Formik, Form } from 'formik'
import { has } from 'lodash'
import i18n from 'i18n'

import MedButton from '@bit/medikura.med-lib-uno.med-button'
import MedTextField from '@bit/medikura.med-lib-uno.med-text-field'
import MedTypography from '@bit/medikura.med-lib-uno.med-typography'

import Card from 'components/card'
import AppLogo from 'components/appLogo'
import { loginUser } from 'store/reducers/auth'
import { isUserLoggedIn, didLoginFailed } from 'store/selectors/user'
import { isValidEmail } from 'utils/validation-utility'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  formContainer: {
    height: '100%',
    textAlign: 'center',
    padding: theme.spacing(8, 0)
  },
  logoWrapper: {
    color: theme.palette.secondary.main,
    paddingBottom: theme.spacing(7),
    '@media (max-height:799px)': {
      paddingBottom: theme.spacing(3.5)
    },
    '@media (max-height:699px)': {
      paddingBottom: theme.spacing(2.5)
    }
  },
  logo: {
    width: '50%',
    margin: '0 auto'
  },
  title: {
    paddingBottom: theme.spacing(7),
    '@media (max-height:699px)': {
      paddingBottom: theme.spacing(2.5)
    }
  },
  textField: {
    paddingBottom: theme.spacing(1.25),
    '&input:-webkit-autofill': {
      animation: 'onAutoFillStart'
    },
    '&input:not(:-webkit-autofill)': {
      animation: 'onAutoFillCancel'
    }
  },
  forgotLinkWrapper: {
    textAlign: 'right'
  },
  alertBoxWrapper: {
    marginTop: theme.spacing(2),
    minHeight: theme.spacing(5)
  },
  btnWrapper: {
    position: 'relative',
    margin: theme.spacing(7, 'auto', 0, 'auto'),
    '@media (max-height:699px)': {
      marginTop: theme.spacing(1.5)
    },

    '& button': {
      margin: 0
    }
  },
  progressIconOnBtn: {
    position: 'absolute',
    top: 'calc(50% - 12px)',
    left: 'calc(50% - 12px)'
  },
  links: {
    textDecoration: 'none',
    color: theme.palette.text.secondary
  },
  '@keyframes onAutoFillStart': { from: {} },
  '@keyframes onAutoFillCancel': { from: {} }
}))

function LoginForm () {
  const classes = useStyles()
  const dispatch = useDispatch()
  const userLoggedIn = useSelector(isUserLoggedIn)
  const loginError = useSelector(didLoginFailed)

  const [isFormAutoFilled, setIsFormAutoFilled] = useState(false)
  const navigate = useNavigate()
  const inputRef = useRef()

  useEffect(() => {
    inputRef.current &&
      inputRef.current.addEventListener(
        'animationstart',
        onAnimationStart,
        false
      )
  }, [])

  useEffect(() => {
    if (userLoggedIn) {
      navigate('/reports')
    }
  }, [userLoggedIn, navigate])

  const onAutoFillStart = () => setIsFormAutoFilled(true)

  const onAutoFillCancel = () => setIsFormAutoFilled(false)

  const onAnimationStart = ({ animationName }) => {
    switch (animationName) {
      case 'mui-auto-fill':
        return onAutoFillStart()
      case 'mui-auto-fill-cancel':
        return onAutoFillCancel()
    }
  }

  return (
    <>
      <Card className={classes.root}>
        <Grid container justify='center' className={classes.root}>
          <Grid item xs={10} className={classes.root}>
            <div className={classes.formContainer}>
              <Formik
                initialValues={{ email: '', password: '' }}
                validate={values => {
                  const errors = {}
                  if (!values.email || !isValidEmail(values.email)) {
                    errors.email = true
                  }
                  if (!values.password) {
                    errors.password = true
                  }
                  return errors
                }}
                onSubmit={async (values, { setSubmitting }) => {
                  await dispatch(loginUser(values))

                  setSubmitting(false)
                }}
              >
                {({
                  dirty,
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  isSubmitting,
                  isValid
                }) => (
                  <Form>
                    <div className={classes.logoWrapper}>
                      <div className={classes.logo}>
                        <AppLogo />
                      </div>
                    </div>

                    <MedTypography
                      variant='h3'
                      color='secondary'
                      align='center'
                      className={classes.title}
                      data-testid='login-title'
                    >
                      {i18n.t('containers.loginForm.heading')}
                    </MedTypography>
                    <MedTextField
                      variant='outlined'
                      className={classes.textField}
                      fullWidth
                      placeholder={i18n.t(
                        'containers.loginForm.placeholders.email'
                      )}
                      type='email'
                      name='email'
                      inputRef={inputRef}
                      data-testid='email'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      autoFocus
                      value={values.email}
                      error={has(errors, 'email')}
                      helperText={
                        has(errors, 'email')
                          ? i18n.t('containers.loginForm.errors.email')
                          : ' '
                      }
                    />
                    <MedTextField
                      variant='outlined'
                      className={classes.textField}
                      fullWidth
                      placeholder={i18n.t(
                        'containers.loginForm.placeholders.password'
                      )}
                      type='password'
                      name='password'
                      data-testid='password'
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      value={values.password}
                    />
                    <div className={classes.forgotLinkWrapper}>
                      <Link
                        to='/forgotPassword'
                        data-testid='forgot-password-link'
                        className={classes.links}
                      >
                        {i18n.t('containers.loginForm.actions.forgotPassword')}
                      </Link>
                    </div>
                    <div className={classes.alertBoxWrapper}>
                      {loginError && (
                        <Alert severity='error'>
                          {i18n.t('containers.loginForm.errors.loginFailed')}
                        </Alert>
                      )}
                    </div>
                    <Grid item xs={12} sm={5} className={classes.btnWrapper}>
                      <MedButton
                        type='submit'
                        fullWidth
                        disabled={
                          isSubmitting ||
                          !isValid ||
                          (!isFormAutoFilled && !dirty)
                        }
                        data-testid='login-button'
                      >
                        {i18n.t('containers.loginForm.actions.login')}
                      </MedButton>
                      {isSubmitting && (
                        <CircularProgress
                          size={24}
                          className={classes.progressIconOnBtn}
                        />
                      )}
                    </Grid>
                  </Form>
                )}
              </Formik>
            </div>
          </Grid>
        </Grid>
      </Card>
    </>
  )
}

export default LoginForm

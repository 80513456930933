import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'

import { getUserDataApi, updateUserDataApi } from 'store/api/user'

export const initialState = {
  data: {},
  loading: false,
  error: false,
  dataUpdateAttempt: false,
  persistExpiresAt: null
}

const authSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getUserDataRequest: state => {
      state.loading = true
      state.error = false
      state.data = {}
    },
    getUserDataSuccess: (state, action) => {
      state.data = action.payload
      state.loading = false
      state.persistExpiresAt = moment.unix(action.payload.expiresAt).format()
    },
    getUserDataFail: (state, action) => {
      state.error = action.payload.error
      state.loading = false
    },
    updateUserDataRequest: state => {
      state.loading = true
    },
    updateUserDataSuccess: (state, action) => {
      state.loading = false
      state.dataUpdateAttempt = true
      state.dataUpdateSuccess = true
    },
    updateUserDataFail: (state, action) => {
      state.error = action.payload.error
      state.loading = false
      state.dataUpdateAttempt = true
      state.dataUpdateSuccess = false
    }
  },
  extraReducers: {
    'persist/REHYDRATE': (state, action) => {
      if (action.payload && action.payload.data) {
        const { data, persistExpiresAt } = action.payload.user
        state.error = false
        state.data = data
        state.persistExpiresAt = persistExpiresAt
      }
    }
  }
})

export const { name, actions, reducer } = authSlice

export const {
  getUserDataRequest,
  getUserDataSuccess,
  getUserDataFail,
  updateUserDataRequest,
  updateUserDataFail,
  updateUserDataSuccess
} = actions

// action creators
export const getUserData = token => async dispatch => {
  try {
    dispatch(getUserDataRequest())

    const res = await getUserDataApi(token)

    if (res.data.email) {
      dispatch(getUserDataSuccess(res.data))
    }
  } catch (err) {
    if (err) {
      dispatch(getUserDataFail(err))
    }
  }
}

export const updateUserData = (data, token) => async dispatch => {
  try {
    dispatch(updateUserDataRequest())

    const res = await updateUserDataApi(data, token)

    if (res.data) {
      dispatch(updateUserDataSuccess(res.data))
      dispatch(getUserData(token))
    }
  } catch (err) {
    if (err) {
      dispatch(updateUserDataFail(err))
    }
  }
}

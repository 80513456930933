import React from 'react'
import { navigate } from '@reach/router'
import { makeStyles } from '@material-ui/styles'
import i18n from 'i18n'
import Grid from '@material-ui/core/Grid'
import Card from 'components/card'
import MedTypography from '@bit/medikura.med-lib-uno.med-typography'
import MedIcon from '@bit/medikura.med-lib-uno.med-icon'
import MedButton from '@bit/medikura.med-lib-uno.med-button'
import AppLogo from 'components/appLogo'

const useStyles = makeStyles(theme => ({
  card: {
    padding: '50px 40px',
    textAlign: 'center'
  },
  title: {
    paddingBottom: theme.spacing(7),
    '@media (max-height:899px)': {
      paddingBottom: theme.spacing(6)
    },
    '@media (max-height:799px)': {
      paddingBottom: theme.spacing(3.5)
    },
    '@media (max-height:699px)': {
      paddingBottom: theme.spacing(2.5)
    }
  },
  logoWrapper: {
    color: theme.palette.secondary.main,
    paddingBottom: theme.spacing(7),
    '@media (max-height:799px)': {
      paddingBottom: theme.spacing(3.5)
    },
    '@media (max-height:699px)': {
      paddingBottom: theme.spacing(2.5)
    }
  },
  logo: {
    width: '75%',
    margin: '0 auto'
  },
  successIcon: {
    '& .MuiSvgIcon-root': {
      color: theme.palette.success.main
    }
  }
}))

export default function ConfirmPasswordChanged () {
  const classes = useStyles()
  return (
    <Grid container xs={12} sm={10} xl={8} justify='center'>
      <Grid
        container
        item
        xs={12}
        sm={10}
        md={7}
        lg={5}
        xl={4}
        alignContent='center'
      >
        <Card className={classes.card}>
          <div className={classes.logoWrapper}>
            <div className={classes.logo}>
              <AppLogo />
            </div>
          </div>
          <MedTypography
            data-testid='newPasswordTitle'
            variant='h3'
            color='secondary'
            align='center'
            className={classes.title}
          >
            {i18n.t('containers.newPassword.heading')}
          </MedTypography>
          <div className={classes.successIcon}>
            <MedIcon type='success' />
          </div>
          <MedTypography
            variant='h5'
            color='secondary'
            align='center'
            className={classes.title}
          >
            {i18n.t('containers.newPassword.confirm.heading')}
          </MedTypography>
          <MedTypography variant='body1' color='secondary' align='left'>
            {i18n.t('containers.newPassword.confirm.message')}
          </MedTypography>
          <MedButton type='submit' onClick={() => navigate('/')}>
            {i18n.t('containers.newPassword.confirm.loginBtn')}
          </MedButton>
        </Card>
      </Grid>
    </Grid>
  )
}

import React from 'react'
import { fade, makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'

import MedTextField from '@bit/medikura.med-lib-uno.med-text-field'

import i18n from 'i18n'

const useStyles = makeStyles(theme => ({
  search: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: fade(theme.palette.common.white, 0.25),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.55)
    },
    borderRadius: 10,

    transition: theme.transitions.create('width'),
    width: '50%',
    '&:focus-within': {
      width: '100%',
      backgroundColor: fade(theme.palette.common.white, 1)
    }
  },
  searchInput: {
    width: '100%'
  },
  medTextField: {
    padding: 0,
    verticalAlign: 'middle'
  },
  medTextFieldInput: {
    fontSize: '1rem',
    padding: theme.spacing(1.25, 0, 1.25, 2)
  },
  searchIconBtn: {
    padding: theme.spacing(0.875)
  }
}))

function SearchBar () {
  const classes = useStyles()

  return (
    <>
      <div className={classes.search}>
        <div className={classes.searchInput}>
          <MedTextField
            variant='standard'
            fullWidth
            placeholder={i18n.t('components.searchBar.placeholderText')}
            className={classes.medTextField}
            InputProps={{
              disableUnderline: true,
              classes: { input: classes.medTextFieldInput },
              'aria-label': 'search'
            }}
            data-testid='search-input'
          />
        </div>
        <IconButton className={classes.searchIconBtn}>
          <SearchIcon color='secondary' />
        </IconButton>
      </div>
    </>
  )
}

export default SearchBar

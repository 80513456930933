import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import i18n from 'i18n'
import { Formik, Form } from 'formik'
import { has } from 'lodash'
import { makeStyles } from '@material-ui/styles'
import { Grid, InputAdornment } from '@material-ui/core'
import {
  Visibility,
  FiberManualRecord,
  VisibilityOff
} from '@material-ui/icons'
import { useLocation, useNavigate } from '@reach/router'
import Alert from '@material-ui/lab/Alert'
import MedButton from '@bit/medikura.med-lib-uno.med-button'
import MedTextField from '@bit/medikura.med-lib-uno.med-text-field'
import MedTypography from '@bit/medikura.med-lib-uno.med-typography'

import Card from 'components/card'
import AppLogo from 'components/appLogo'
import { setNewPassword } from 'store/reducers/passwordRecovery'
import { didPasswordChangeFail, tokenAlreadyUsed } from 'store/selectors/user'
import PasswordMeter from 'components/passwordMeter'
import config from 'config'
import helpers from 'utils/helpers'

const useStyles = makeStyles(theme => ({
  root: {
    height: 'inherit',
    width: '100%'
  },
  formContainer: {
    height: 'inherit',
    textAlign: 'center',
    padding: theme.spacing(9, 4),
    '@media (max-height:899px)': {
      padding: theme.spacing(7, 4)
    },
    '@media (max-height:799px)': {
      padding: theme.spacing(4.5, 4)
    },
    '@media (max-height:699px)': {
      padding: theme.spacing(3.5, 4)
    }
  },
  title: {
    paddingBottom: theme.spacing(8),
    '@media (max-height:899px)': {
      paddingBottom: theme.spacing(6)
    },
    '@media (max-height:799px)': {
      paddingBottom: theme.spacing(3.5)
    },
    '@media (max-height:699px)': {
      paddingBottom: theme.spacing(2.5)
    }
  },
  btnWrapper: {
    margin: theme.spacing(2, 'auto', 0, 'auto'),
    '@media (max-height:899px)': {
      marginTop: theme.spacing(2.5)
    },
    '@media (max-height:799px)': {
      marginTop: theme.spacing(2)
    },
    '@media (max-height:699px)': {
      marginTop: theme.spacing(1.5)
    },

    '& button': {
      margin: 0
    }
  },
  visibilityIcons: {
    color: theme.palette.primary.dark,
    cursor: 'pointer'
  },
  helperTextContainerEnabled: {
    display: 'flex',
    marginTop: '5px',
    marginBottom: '30px',
    color: theme.palette.secondary.main
  },
  helperTextContainerDisabled: {
    display: 'flex',
    marginTop: '5px',
    marginBottom: '30px',
    color: theme.palette.text.disabled
  },
  helperTextSvg: {
    fontSize: '12px',
    alignSelf: 'center'
  },
  helperText: {
    fontSize: '12px',
    paddingLeft: '5px'
  },
  alertBoxWrapper: {
    marginTop: theme.spacing(0),
    minHeight: theme.spacing(0)
  },
  logoWrapper: {
    color: theme.palette.secondary.main,
    paddingBottom: theme.spacing(7),
    '@media (max-height:799px)': {
      paddingBottom: theme.spacing(3.5)
    },
    '@media (max-height:699px)': {
      paddingBottom: theme.spacing(2.5)
    }
  },
  logo: {
    width: '50%',
    margin: '0 auto'
  },
  newPasswordInputContainer: {
    width: '100%'
  }
}))

function NewPasswordForm () {
  const classes = useStyles()
  const dispatch = useDispatch()

  const location = useLocation()
  const navigate = useNavigate()

  const [showPassword, setShowPassword] = useState(false)
  const [showRepeatPassword, setShowRepeatPassword] = useState(false)
  const [toggleMeter, setToggleMeter] = useState(false)
  const [jwtToken] = useState(location.search.split('token=')[1])
  const [isJwtTokenValid] = useState(helpers.isTokenValid(jwtToken))

  const passwordChangeError = useSelector(didPasswordChangeFail)
  const tokenUsed = useSelector(tokenAlreadyUsed)

  return (
    <>
      <Card className={classes.root}>
        <div className={classes.formContainer}>
          <Formik
            initialValues={{ email: '' }}
            validate={values => {
              const errors = {}
              if (!values.newPassword) {
                errors.newPassword = i18n.t(
                  'containers.newPassword.errors.passwordMissing'
                )
              } else if (values.newPassword.length < config.MIN_PASS_LENGTH) {
                errors.newPassword = 'minCharError'
              }
              if (!values.repeatNewPassword) {
                errors.repeatNewPassword = i18n.t(
                  'containers.newPassword.errors.repeat'
                )
              } else if (values.repeatNewPassword !== values.newPassword) {
                errors.repeatNewPassword = i18n.t(
                  'containers.newPassword.errors.noMatch'
                )
              }
              return errors
            }}
            onSubmit={async (values, { setSubmitting }) => {
              await dispatch(
                setNewPassword(
                  {
                    newPassword: values.newPassword
                  },
                  location.search.split('token=')[1]
                )
              )

              setSubmitting(false)
            }}
          >
            {({
              dirty,
              values,
              errors,
              handleChange,
              handleBlur,
              isSubmitting,
              setFieldValue
            }) => (
              <Form>
                <div className={classes.logoWrapper}>
                  <div className={classes.logo}>
                    <AppLogo />
                  </div>
                </div>
                <MedTypography
                  data-testid='newPasswordTitle'
                  variant='h3'
                  color='secondary'
                  align='center'
                  className={classes.title}
                >
                  {i18n.t('containers.newPassword.heading')}
                </MedTypography>
                <MedTypography variant='body1' color='secondary' align='left'>
                  {i18n.t('containers.newPassword.label')}
                </MedTypography>
                <PasswordMeter open={toggleMeter} password={values.newPassword}>
                  <MedTextField
                    className={classes.newPasswordInputContainer}
                    variant='outlined'
                    placeholder='Password'
                    type={showPassword ? 'text' : 'password'}
                    name='newPassword'
                    data-testid='newPassword'
                    onChange={event => {
                      setFieldValue('newPassword', event.target.value)
                      if (event.target.value.length >= config.MIN_PASS_LENGTH) {
                        setToggleMeter(true)
                      } else {
                        setToggleMeter(false)
                      }
                    }}
                    onBlur={() => {
                      setToggleMeter(false)
                    }}
                    required
                    value={values.newPassword}
                    error={has(errors, 'newPassword') && values.newPassword}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          className={classes.visibilityIcons}
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </InputAdornment>
                      )
                    }}
                  />
                </PasswordMeter>
                <div
                  className={
                    errors.newPassword === 'minCharError' || !values.newPassword
                      ? classes.helperTextContainerEnabled
                      : classes.helperTextContainerDisabled
                  }
                >
                  <FiberManualRecord className={classes.helperTextSvg} />
                  <MedTypography
                    variant='body2'
                    align='left'
                    className={classes.helperText}
                  >
                    {i18n.t('containers.newPassword.errors.minimum')}
                  </MedTypography>
                </div>
                <MedTypography variant='body1' color='secondary' align='left'>
                  {i18n.t('containers.newPassword.repeatLabel')}
                </MedTypography>
                <MedTextField
                  variant='outlined'
                  placeholder='Password'
                  type={showRepeatPassword ? 'text' : 'password'}
                  name='repeatNewPassword'
                  data-testid='repeatNewPassword'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  value={values.repeatNewPassword}
                  error={
                    has(errors, 'repeatNewPassword') && values.repeatNewPassword
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        className={classes.visibilityIcons}
                        onClick={() =>
                          setShowRepeatPassword(!showRepeatPassword)
                        }
                      >
                        {showRepeatPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </InputAdornment>
                    )
                  }}
                  helperText={
                    has(errors, 'repeatNewPassword') && values.repeatNewPassword
                      ? errors.repeatNewPassword
                      : ' '
                  }
                />
                <div className={classes.alertBoxWrapper}>
                  {(!isJwtTokenValid && (
                    <Alert severity='error'>
                      {i18n.t('containers.newPassword.errors.tokenExpired')}
                    </Alert>
                  )) ||
                    (tokenUsed && (
                      <Alert severity='error'>
                        {i18n.t(
                          'containers.newPassword.errors.tokenAlreadyUsed'
                        )}
                      </Alert>
                    )) ||
                    (passwordChangeError && (
                      <Alert severity='error'>
                        {i18n.t(
                          'containers.newPassword.errors.changePasswordFailed'
                        )}
                      </Alert>
                    ))}
                </div>
                <Grid
                  item
                  xs={12}
                  sm={10}
                  md={9}
                  className={classes.btnWrapper}
                >
                  {((!isJwtTokenValid || tokenUsed || passwordChangeError) && (
                    <MedButton
                      type=''
                      fullWidth
                      onClick={() => navigate('/forgotPassword')}
                    >
                      {i18n.t('containers.newPassword.actions.requestAgain')}
                    </MedButton>
                  )) || (
                    <MedButton
                      type='submit'
                      data-testid='changePasswordBtn'
                      fullWidth
                      disabled={
                        !dirty ||
                        isSubmitting ||
                        !!errors.newPassword ||
                        !!errors.repeatNewPassword
                      }
                    >
                      {i18n.t('containers.newPassword.actions.changePass')}
                    </MedButton>
                  )}
                </Grid>
              </Form>
            )}
          </Formik>
        </div>
      </Card>
    </>
  )
}

export default NewPasswordForm

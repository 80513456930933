import { identityInstanceAxios as identity } from 'store/api'

const SEND_EMAIL_URL = '/pharma/reset'
const NEW_PASSWORD_URL = '/pharma/reset/newPassword'

export async function sendForgotPassEmailAPI (data) {
  return identity.post(SEND_EMAIL_URL, {
    email: data.email,
    callbackURL: data.callbackURL
  })
}

export async function setNewPasswordAPI (data, token) {
  const config = {
    url: NEW_PASSWORD_URL,
    method: 'POST',
    data: {
      newPassword: data.newPassword
    },
    headers: { Authorization: `Bearer ${token}` }
  }
  return identity(config)
}

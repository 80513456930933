import React from 'react'
import { Link } from '@reach/router'

import { makeStyles } from '@material-ui/styles'

import { ReactComponent as Logo } from 'assets/img/xolife-logo.svg'

const useStyles = makeStyles(theme => ({
  root: {
    color: 'inherit'
  },
  logo: {
    width: '100%',
    height: '100%'
  }
}))

function AppLogo () {
  const classes = useStyles()

  return (
    <Link to='/' className={classes.root}>
      <Logo className={classes.logo} />
    </Link>
  )
}

export default AppLogo

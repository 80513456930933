export default {
  IDENTITY_BASE_URL: process.env.REACT_APP_API_IDENTITY,
  EVENTSTORE_BASE_URL: process.env.REACT_APP_API_EVENTSTORE_PRIVATE,
  EXPORT_BASE_URL: process.env.REACT_APP_API_EXPORT,
  INTERCOM_APP_ID: process.env.REACT_APP_INTERCOM_ID,

  MATOMO_URL: process.env.REACT_APP_MATOMO_URL,
  MATOMO_ENV: process.env.REACT_APP_MATOMO_ENV,
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
  SENTRY_ENV: process.env.REACT_APP_SENTRY_ENV,

  NODE_ENV: process.env.NODE_ENV,

  MIN_PASS_LENGTH: 8,
  PASSWORD_STRENGTH_HIGH: 'high',
  PASSWORD_STRENGTH_LOW: 'low',
  PASSWORD_STRENGTH_MEDIUM: 'medium'
}

import React from 'react'

import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: '12px'
  }
}))

function Card (props) {
  const classes = useStyles()

  return (
    <Paper elevation={0} className={`${classes.root} ${props.className}`}>
      {props.children}
    </Paper>
  )
}

export default Card

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'

const useStyles = makeStyles(theme => ({
  searchIcon: {
    color: theme.palette.common.white,
    verticalAlign: 'middle'
  }
}))

function SearchPopper () {
  const classes = useStyles()

  return (
    <>
      <div>
        <IconButton>
          <SearchIcon color='secondary' className={classes.searchIcon} />
        </IconButton>
      </div>
    </>
  )
}

export default SearchPopper

import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import i18n from 'i18n'

import MedTypography from '@bit/medikura.med-lib-uno.med-typography'

import ProfileContainer from 'containers/profileForm'

const useStyles = makeStyles(theme => ({
  root: {
    padding: '30px'
  },
  headingContainer: {
    height: '63px'
  },
  gridContainer: {
    height: '100%'
  },
  heading: {
    color: theme.palette.secondary.main
  }
}))
function SettingsProfile () {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Grid container spacing={3} className={classes.gridContainer}>
        <Grid
          container
          item
          xs={12}
          justify='space-between'
          className={classes.headingContainer}
        >
          <MedTypography
            variant='h4'
            className={classes.heading}
            data-testid='profile-title'
          >
            {i18n.t('containers.settings.navigation.profile')}
          </MedTypography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          alignContent='space-between'
          justify='center'
        >
          <ProfileContainer />
        </Grid>
      </Grid>
    </div>
  )
}

export default SettingsProfile

import { useSelector } from 'react-redux'
import { Redirect } from '@reach/router'

import { isUserLoggedIn } from 'store/selectors/user'

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const userLoggedIn = useSelector(isUserLoggedIn)

  return userLoggedIn ? (
    <Component {...rest} />
  ) : (
    <Redirect from='' to='/login' noThrow />
  )
}

export default ProtectedRoute

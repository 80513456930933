import React from 'react'
import ReactDOM from 'react-dom'

import App from 'containers/app'
import reportWebVitals from 'reportWebVitals'
import config from 'config'

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('medAppEngage')
)

if (config.NODE_ENV === 'development') {
  reportWebVitals(console.log)
}

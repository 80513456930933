import { createSelector } from '@reduxjs/toolkit'

export const isUserLoggedIn = createSelector(
  state => state.auth.isAuthenticated,
  isAuthenticated => isAuthenticated
)

export const didLoginFailed = createSelector(
  state => state.auth.error,
  isAuthFailed => isAuthFailed
)

export const retrieveUserData = createSelector(
  state => state.user.data,
  data => data
)

export const getUserToken = createSelector(
  state => state.auth.user.token,
  token => token
)

export const userUpdateAttempt = createSelector(
  state => state.user.dataUpdateAttempt,
  dataUpdateAttempt => dataUpdateAttempt
)

export const userUpdateSuccess = createSelector(
  state => state.user.dataUpdateSuccess,
  dataUpdateSuccess => dataUpdateSuccess
)

export const isRecoverPasswordEmailSent = createSelector(
  state => state.password.recoverPasswordEmailSent,
  recoverPasswordEmailSent => recoverPasswordEmailSent
)

export const isPasswordChanged = createSelector(
  state => state.password.changed,
  changed => changed
)

export const didPasswordChangeFail = createSelector(
  state => state.password.error,
  error => error
)

export const tokenAlreadyUsed = createSelector(
  state => state.password.tokenAlreadyUsed,
  tokenAlreadyUsed => tokenAlreadyUsed
)

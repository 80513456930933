import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'

import { loginAPI } from 'store/api/auth'
import { getUserData } from 'store/reducers/user'

export const initialState = {
  isAuthenticated: false,
  user: {},
  loading: false,
  error: false,
  persistExpiresAt: null
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginRequest: state => {
      state.isAuthenticated = false
      state.loading = true
      state.error = false
      state.user = {}
    },
    loginSuccess: (state, action) => {
      state.isAuthenticated = true
      state.user = action.payload
      state.loading = false
      state.persistExpiresAt = moment.unix(action.payload.expiresAt).format()
    },
    loginFailure: (state, action) => {
      state.error = action.payload.error
      state.loading = false
    },
    logoutRequest: () => initialState
  },
  extraReducers: {
    'persist/REHYDRATE': (state, action) => {
      if (action.payload && action.payload.auth) {
        const { isAuthenticated, user, persistExpiresAt } = action.payload.auth
        state.error = false
        state.isAuthenticated = isAuthenticated
        state.user = user
        state.persistExpiresAt = persistExpiresAt
      }
    }
  }
})

export const { name, actions, reducer } = authSlice

export const {
  loginRequest,
  loginSuccess,
  loginFailure,
  logoutRequest
} = actions

// action creators
export const loginUser = data => async dispatch => {
  try {
    dispatch(loginRequest())

    const res = await loginAPI(data)

    if (res.data.token) {
      dispatch(loginSuccess(res.data))
      dispatch(getUserData(res.data.token))
    }
  } catch (err) {
    if (err.response.data) {
      dispatch(loginFailure(err.response.data))
    }
  }
}

export const logoutUser = () => async dispatch => {
  dispatch(logoutRequest())
}

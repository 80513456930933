import { combineReducers } from '@reduxjs/toolkit'

import { reducer as authReducer } from './auth'
import { reducer as passwordReducer } from './passwordRecovery'
import { reducer as userReducer } from './user'

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  password: passwordReducer
})

export default rootReducer

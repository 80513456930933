import React from 'react'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuList from '@material-ui/core/MenuList'
import IconButton from '@material-ui/core/IconButton'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'

import { retrieveUserData } from 'store/selectors/user'
import { SettingsNav } from 'components/appNavItems'
import Logout from 'components/logout'
import UserAvatar from 'components/userAvatar'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  btnRoot: {
    paddingRight: 0,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  expandIcon: {
    color: theme.palette.common.white
  },

  container: {
    display: 'flex'
  },
  paper: {
    margin: theme.spacing(1)
  },

  menuList: {
    '& .MuiListItem-root': {
      color: theme.palette.secondary.main,
      fontSize: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightRegular,

      '& > *': {
        color: theme.palette.secondary.main
      }
    }
  }
}))

export default function HeaderUserMenu () {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)

  const userData = useSelector(retrieveUserData)
  const anchorRef = React.useRef(null)

  const handleToggle = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  // TODO :: Fetch user details & get avatar by color seed

  return (
    <div className={classes.root}>
      <IconButton
        disableRipple
        disableFocusRipple
        className={classes.btnRoot}
        onClick={handleToggle}
        ref={anchorRef}
      >
        <UserAvatar userData={userData} />
        <ExpandMoreIcon className={classes.expandIcon} />
      </IconButton>

      {open && (
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement='bottom-end'
          transition
          disablePortal
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: 'center top'
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList className={classes.menuList}>
                    <SettingsNav isButton />
                    <Logout />
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
    </div>
  )
}

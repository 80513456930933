import jwtDecode from 'jwt-decode'
import Seedrandom from 'seedrandom'

function decodeToken (token) {
  try {
    return jwtDecode(token)
  } catch (error) {}
}

function isTokenValid (token) {
  const decodedToken = decodeToken(token)

  if (!decodedToken || Date.now() >= decodedToken.exp * 1000) {
    return false
  }

  return true
}

export const getSeededRandomNumber = seed => {
  // returns a deterministic random number from 0 to 8
  if (seed) {
    const seededRandom = new Seedrandom(seed)
    return Math.round(seededRandom.quick() * 8)
  }
}

const helperFunctions = {
  decodeToken,
  isTokenValid,
  getSeededRandomNumber
}
export default helperFunctions
